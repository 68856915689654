import React from 'react';
import PropTypes from 'prop-types';
import SEO from '../components/SEO/SEO';
import Buttons from '../components/Buttons';
import Slider from '../components/Slider';
import { withWidth } from '../components/withWidth';
import constants from '../constants/javascriptConstants';
import SvgKupStarterPackCovid from '../svg/covid/SvgKupStarterPackCovid';
import SvgKupStarterPack from '../svg/buttonsSeparate/buyStarterpackButtons/SvgKupStarterPack';
import Button from '../components/Buttons/button';


class Index extends React.Component {
    static propTypes = {
        width: PropTypes.number.isRequired,
    };

    render() {
        const { width } = this.props;

        return (
            <section id="buttons" ref={this.sliderRef}>
                <SEO />
                <Slider indexPage>
                    <Buttons width={width}/>
                </Slider>
            </section>
        );
    }
}

export default  withWidth(Index);
